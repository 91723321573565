<template>
  <div class="va-row">
    <div class="flex md12 xs12">
      <vuestic-widget
        :headerText="'update-extension.update-extension' | translate"
      >
        <half-circle-spinner
          :animation-duration="1500"
          :size="50"
          color="#008ecf"
          v-if="loading"
        />
        <div class="" v-else>
          <div class="row mb-4">
            <div class="col-12 col-lg-6">
              <field
                :label="$t('update-extension.form.name')"
                v-model="model.name"
                :error="errors.name"
              ></field>
            </div>
            <div class="col-12 col-lg-6">
              <field
                :label="$t('update-extension.form.email')"
                v-model="model.email"
                :error="errors.email"
              ></field>
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-12 col-lg-6">
              <field
                type="number"
                :label="$t('update-extension.form.extension-number')"
                v-model="model.ext"
                :error="errors.ext"
                :disabled="true"
              ></field>
            </div>
            <div class="col-12 col-lg-6">
              <field
                :type="passwordVisible ? 'text' : 'password'"
                :label="$t('update-extension.form.password')"
                v-model="model.password"
                :error="errors.password"
              ></field>
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-12 col-lg-6">
              <field
                :label="$t('update-extension.form.inbound-calls')"
                :error="errors.inboundCalls"
              >
                <vuestic-switch v-model="model.inbound">
                  <span slot="trueTitle">{{
                    $t('update-extension.form.enable')
                  }}</span>
                  <span slot="falseTitle">{{
                    $t('update-extension.form.disable')
                  }}</span>
                </vuestic-switch></field
              >
            </div>
            <div class="col-12 col-lg-6">
              <field
                :label="$t('update-extension.form.outbound-calls')"
                :error="errors.outboundCalls"
              >
                <vuestic-switch v-model="model.outbound">
                  <span slot="trueTitle">{{
                    $t('update-extension.form.enable')
                  }}</span>
                  <span slot="falseTitle">{{
                    $t('update-extension.form.disable')
                  }}</span>
                </vuestic-switch></field
              >
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-12 col-lg-6">
              <field
                :label="$t('update-extension.form.voicemail')"
                :error="errors.voicemail"
              >
                <vuestic-switch v-model="model.voicemail">
                  <span slot="trueTitle">{{
                    $t('update-extension.form.yes')
                  }}</span>
                  <span slot="falseTitle">{{
                    $t('update-extension.form.no')
                  }}</span>
                </vuestic-switch>
              </field>
            </div>
            <div class="col-12 col-lg-6">
              <field
                type="number"
                :label="$t('update-extension.form.pin')"
                v-model="model.pin"
                :error="errors.pin"
              ></field>
            </div>
          </div>
          <div class="row">
            <div class="col-12 d-flex justify-content-center">
              <button
                @click="updateExtension"
                class="btn btn-primary btn-sm btn-with-icon-sm rounded"
                :disabled="updateLoading"
              >
                <half-circle-spinner
                  slot="loading"
                  :animation-duration="1500"
                  :size="20"
                  color="#fff"
                  v-if="updateLoading"
                />
                <span v-else class="btn-with-icon-content">
                  <i class="ion-md-add ion"></i>
                  {{ $t('update-extension.form.update') }}
                </span>
              </button>
            </div>
          </div>
        </div>
      </vuestic-widget>
    </div>
    <modal
      :modalContentStyle="{ width: '300px' }"
      :isOpen="demoCallSupportModalOpen"
      @dismiss="demoCallSupportModalOpen = false"
      inno
    >
      <DemoCallSupportModal />
    </modal>

    <modal
      :modalContentStyle="{ width: '300px' }"
      :isOpen="actionDeniedModalOpen"
      @dismiss="actionDeniedModalOpen = false"
      inno
    >
      <ActionDeniedModal />
    </modal>
  </div>
</template>
<script>
import HalfCircleSpinner from 'epic-spinners/src/components/lib/HalfCircleSpinner'
import Field from '../../../elements/Field.vue'
import Modal from '../../../elements/Modal.vue'
import { object, string } from 'yup'
import ExtensionService from '../../../services/extension.service'
import { yupToKV } from '../../../utils/yup'
import CryptoJS from 'crypto-js'
import DemoCallSupportModal from '../../../elements/DemoCallSupportModal.vue'
import ActionDeniedModal from '../../../elements/ActionDeniedModal.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    HalfCircleSpinner,
    Field,
    Modal,
    DemoCallSupportModal,
    ActionDeniedModal
  },
  data() {
    return {
      updateLoading: false,
      loading: false,
      model: {
        name: '',
        email: '',
        ext: '',
        inbound: true,
        outbound: true,
        voicemail: false,
        pin: Math.floor(1000 + Math.random() * 9000).toString(),
        password: ''
      },
      errors: {},
      passwordVisible: false,
      demoCallSupportModalOpen: false,
      actionDeniedModalOpen: false
    }
  },
  computed: {
    ...mapGetters(['currentTenant', 'currentUser']),
    updateSchema() {
      return object().shape({
        name: string()
          .trim()
          .matches(
            /^[a-zA-Z0-9\s\p{P}]+$/u,
            this.$t('update-extension.errors.invalid.name')
          )
          .required(this.$t('update-extension.errors.required.name')),
        email: string()
          .email(this.$t('update-extension.errors.invalid.email'))
          .required(this.$t('update-extension.errors.required.email')),
        ext: string()
          .min(3, this.$t('update-extension.errors.invalid.extension-number'))
          .required(
            this.$t('update-extension.errors.required.extension-number')
          ),
        password: string()
          .trim()
          .min(6, this.$t('update-extension.errors.length.password'))
          .matches(
            /^[a-zA-Z0-9%\\$\\*!@\\_\\-]+$/,
            this.$t('update-extension.errors.invalid.password')
          )
          .required(this.$t('update-extension.errors.required.password')),
        pin: string()
          .trim()
          .matches(/^\d{4}$/, this.$t('update-extension.errors.invalid.pin'))
          .required(this.$t('update-extension.errors.required.pin'))
      })
    }
  },
  async mounted() {
    this.getExtension()
  },
  methods: {
    async updateExtension() {
      if (this.currentTenant.isDemo) {
        this.demoCallSupportModalOpen = true
        return
      }

      if (this.currentUser.role != 'Admin') {
        this.actionDeniedModalOpen = true
        return
      }

      this.updateLoading = true

      await this.updateSchema
        .validate(this.model, { abortEarly: false })
        .catch(err => {
          this.errors = yupToKV(err)
        })

      if (this.updateSchema.isValidSync(this.model)) {
        this.errors = {}

        try {
          await ExtensionService.updateExtension(this.$route.params.id, {
            ...this.model,
            ext: +this.model.ext,
            inbound: +this.model.inbound,
            outbound: +this.model.outbound,
            voicemail: +this.model.voicemail
          })
          this.toast(this.$t('update-extension.toasts.updated-successfully'))
          this.$router.push({ name: 'all-extensions' })
        } catch (error) {
          this.toast(error.response.data.message, { type: 'error' })
        } finally {
          this.updateLoading = false
        }
      } else {
        this.updateLoading = false
      }
    },
    async getExtension() {
      try {
        this.loading = true
        const response = await ExtensionService.getOneExtension(
          this.$route.params.id
        )
        const passwordBytes = CryptoJS.AES.decrypt(
          response.password,
          process.env.VUE_APP_ENCRYPT_SECRET
        )
        const decryptedPassword = passwordBytes.toString(CryptoJS.enc.Utf8)
        this.model = { ...response, password: decryptedPassword }
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },
    togglePassword() {
      this.passwordVisible = !this.passwordVisible
    }
  }
}
</script>
